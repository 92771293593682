import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';

import { trackEvent } from 'utils/eventTracking';

import { Autocomplete, Button, Checkbox, CustomModal, Select, TextField } from 'components/v3';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import OrganizationIcon from 'svg/organization.svg';
import LockIcon from 'svg/lock2.svg';
import CopyIcon from 'svg/copy.svg';

import IconContainer from './IconContainer';
import UsersList from './UsersList';

import nameSplit from 'utils/nameSplit';
import useMediaQuery from 'utils/mediaQueries';

import { layouts } from 'config/constants';

import styles from './ShareModal.styles.scss';

const customClasses = { 'MuiOutlinedInput-notchedOutline': { border: 'solid thin var(--light-gray) !important' } };

// initial state of the user/role search box
const initialSearchBoxState = {
  value: '',
  inputText: ''
};

// initial state of the other UI components
const initialState = {
  usersAndRoles: [],
  isSharedWithOrg: false,
  isEnabledByDefault: false
};

const Title = ({ children }) => <span className={styles.title}>{children}</span>;

const ShareModal = ({
  boardId,
  boardSharingUrl,
  title,
  open,
  currentUser, // the user using the modal
  allUsersAndRoles, // all users and roles in the organization
  isShared, // whether the board has any active sharing
  sharedWithUsersAndRoles,
  isSharedWithOrg,
  isEnabledByDefault,
  onConfirm,
  onClose,
  loading,
  disabled,
  t,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery();

  // We leave it out of "default props" because it needs translation;
  const defaultTitle = title || t('sharing.share');

  const isAdminOrSuperUser = currentUser.isAdmin || currentUser.isSuperUser;

  // Translate the general access options
  const generalAccessOptions = {
    restricted: {
      value: 'restricted',
      label: t('sharing.restricted'),
      description: isAdminOrSuperUser
        ? t('sharing.restricted-message-users-and-roles')
        : t('sharing.restricted-message-users'),
      icon: LockIcon
    },
    organization: {
      value: 'organization',
      label: t('sharing.organization'),
      description: t('sharing.organization-message'),
      icon: OrganizationIcon
    }
  };

  // UI state of the user/role search box
  const [searchBoxState, setSearchBoxState] = useState(initialSearchBoxState);

  // UI state of the other components, reflecting the selected sharing
  const [state, setState] = useState(initialState);

  const selectedGeneralAcccessOption = state.isSharedWithOrg
    ? generalAccessOptions.organization.value
    : generalAccessOptions.restricted.value;

  // Whether the selected sharing is different from the effective sharing stored at the backend
  const isDirty =
    isSharedWithOrg !== state.isSharedWithOrg ||
    // the isEnabledByDefault flag is only relevant if isSharedWithOrg is true
    (state.isSharedWithOrg && isEnabledByDefault !== state.isEnabledByDefault) ||
    // Stringify is an ugly workaround to compare two javascript string arrays in a "shallow" manner because Javascript
    // does not provide functionality for this...
    JSON.stringify([...sharedWithUsersAndRoles].sort()) !== JSON.stringify([...state.usersAndRoles].sort());
  // Load current sharing state (if any)
  useEffect(() => {
    setState({
      usersAndRoles: sharedWithUsersAndRoles,
      isSharedWithOrg,
      isEnabledByDefault
    });
  }, [sharedWithUsersAndRoles, isSharedWithOrg, isEnabledByDefault]);

  const removeUserOrRole = userOrRole => {
    setState(prev => {
      const newUsersAndRoles = prev.usersAndRoles.filter(value => value !== userOrRole.value);
      return { ...prev, usersAndRoles: newUsersAndRoles };
    });
  };

  const handleAccessOptionsChange = event => {
    const value = event.target.value;
    setState(prev => ({ ...prev, isSharedWithOrg: value === generalAccessOptions.organization.value }));
  };

  const resetState = () => {
    setState(initialState);
    setSearchBoxState(initialSearchBoxState);
  };

  const handleSelectChange = (_, value) => {
    setState(prev => {
      const newUsersAndRoles = [value, ...prev.usersAndRoles];
      return { ...prev, usersAndRoles: newUsersAndRoles };
    });
    setSearchBoxState(initialSearchBoxState);
  };

  const handleInputChange = (_, value) => setSearchBoxState(prev => ({ ...prev, inputText: value }));
  const handleCheckboxChange = value => setState(prev => ({ ...prev, isEnabledByDefault: value }));

  const handleConfirm = () => {
    const payload = {
      ...state,
      isEnabledByDefault: currentUser.isSuperUser && state.isSharedWithOrg ? state.isEnabledByDefault : false
    };
    onConfirm(payload);
    onClose();
    resetState();
  };

  const copyLink = () => {
    navigator.clipboard.writeText(boardSharingUrl);
    trackEvent('Copied board link to clipboard', { username: currentUser.username, boardSharingUrl });
    enqueueSnackbar(`${t('sharing.link-copied-to-clipboard')}!`, {
      variant: 'info',
      anchorOrigin: { vertical: 'right', horizontal: 'right' },
      autoHideDuration: 1500,
      ...(isMobile ? { action: <></> } : {}) // Necessary to remove the "x" close button from the snackbar.
    });
  };

  const sortedUsersAndRoles = allUsersAndRoles.sort(
    (u1, u2) =>
      // place users first, then roles (each sorted by name)
      [u1.type === 'user' ? 0 : 1, u1.label] > [u2.type === 'user' ? 0 : 1, u2.label]
  );
  const availableUsersAndRoles = useMemo(
    () =>
      sortedUsersAndRoles.reduce((acc, user) => {
        if (!state.usersAndRoles.includes(user.value)) {
          acc.push(user);
        }
        return acc;
      }, []),
    [allUsersAndRoles, state.usersAndRoles]
  );

  const renderOption = (props, option) => (
    <li {...props}>
      {option.type === 'user' && (
        <span className={clsx(styles.menuItemIcon, styles.userInitials)}>{nameSplit(option.label)}</span>
      )}

      {option.type === 'role' && option.icon && (
        <span className={styles.menuItemIcon}>
          <option.icon />
        </span>
      )}

      <span className={clsx(styles.menuItemLabel, styles.menuItemFont)}>{option.label}</span>
    </li>
  );

  const formattedAccessOptions = Object.values(generalAccessOptions);

  const confirmButtonText = useMemo(() => {
    if (!isShared) {
      return t('sharing.share');
    }
    if (state.usersAndRoles.length < 1 && !state.isSharedWithOrg) {
      return t('sharing.stop');
    }
    return t('sharing.update');
  }, [state.usersAndRoles, state.isSharedWithOrg, isShared]);

  const placeholderText = isAdminOrSuperUser ? t('sharing.search-for-users-and-roles') : t('sharing.search-for-users');

  return (
    <CustomModal
      layout={layouts.veezoo}
      title={defaultTitle}
      content={
        <>
          <Autocomplete
            options={availableUsersAndRoles}
            value={searchBoxState.value}
            onChange={handleSelectChange}
            inputValue={searchBoxState.inputText}
            onInputChange={handleInputChange}
            placeholder={`${placeholderText}...`}
            renderOption={renderOption}
            disabled={loading}
            noOptionsText={t('no-options')}
            data-test="shareModalUserAutocomplete"
            title={placeholderText}
          />
          <Title>
            {isAdminOrSuperUser ? t('sharing.users-and-roles-with-access') : t('sharing.users-with-access')}
          </Title>
          <UsersList
            allUsersAndRoles={sortedUsersAndRoles}
            values={state.usersAndRoles}
            onRemoveClick={removeUserOrRole}
            disabled={loading}
            currentUser={currentUser}
            isAdminOrSuperUser={isAdminOrSuperUser}
            t={t}
          />
          <Title>{t('sharing.general-access')}</Title>
          <div className={styles.accessContainer}>
            <div className={styles.displayFlex}>
              <div className={styles.accessIcon}>
                <IconContainer icon={generalAccessOptions[selectedGeneralAcccessOption].icon} />
              </div>
              <div className={styles.accessFields}>
                <span className={styles.field}>
                  <Select
                    layout="veezoo"
                    value={selectedGeneralAcccessOption}
                    onChange={handleAccessOptionsChange}
                    noBorders
                    disabled={loading}
                    options={formattedAccessOptions}
                    data-test="shareModalGeneralAccessSelect"
                  />
                </span>
                <span className={styles.description}>
                  {generalAccessOptions[selectedGeneralAcccessOption].description}
                </span>
                {currentUser.isSuperUser && state.isSharedWithOrg && (
                  <div className={styles.checkboxContainer}>
                    <Checkbox
                      checked={state.isEnabledByDefault}
                      onChange={handleCheckboxChange}
                      disableRipple
                      layout="veezoo"
                      size="small"
                      disabled={loading}
                      content={t('sharing.add-to-everyone-sidebar')}
                      data-test="shareModalAddToSidebarByDefault"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Title>{t('sharing.board-link')}</Title>
          <div className={styles.copyField}>
            <TextField
              layout="veezoo"
              value={boardSharingUrl}
              disabled
              customClasses={customClasses}
              data-test="shareModalBoardSharingUrl"
            />
            <InfoTooltip text={t('sharing.copy-link')}>
              <div className={styles.copyIconContainer} onClick={copyLink} data-test="shareModalCopyLink">
                <CopyIcon className={styles.copyIcon} />
              </div>
            </InfoTooltip>
          </div>
        </>
      }
      loading={loading}
      disabled={disabled || loading}
      open={open}
      onClose={onClose}
      // Make sure to reset the state in case the modal is closed.
      // Have in mind that Mui's modal is always rendered, even though it's invisible and "open" is false.
      TransitionProps={{ onExited: resetState }}
      data-test="shareModal"
      DialogActionsProps={{ classes: { root: styles.dialogActions } }}
      customButtons={
        <div className={styles.customButtonsContainer}>
          <Button
            onClick={handleConfirm}
            layout={layouts.veezoo}
            mode="dark"
            disabled={!isDirty || disabled || loading}
          >
            {confirmButtonText}
          </Button>
        </div>
      }
      {...props}
    />
  );
};

export default ShareModal;

ShareModal.propTypes = {
  boardId: PropTypes.string.isRequired,
  boardSharingUrl: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  allUsersAndRoles: PropTypes.array.isRequired,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isShared: PropTypes.bool,
  isSharedWithOrg: PropTypes.bool,
  sharedWithUsersAndRoles: PropTypes.arrayOf(PropTypes.string),
  isEnabledByDefault: PropTypes.bool,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func
};

ShareModal.defaultProps = {
  boardId: '',
  onConfirm: () => {},
  onClose: () => {},
  loading: false,
  disabled: false,
  t: i => i
};
