import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: ({ isMobile }) => ({
    ...(isMobile ? { marginLeft: '10px' } : { marginRight: '10px' }),
    paddingRight: '10px',
    width: '31px',
    height: '31px',
    '& .MuiButtonBase-root': {
      minWidth: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'var(--accent-normal-color)',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'var(--primary-color)',
        '& .MuiSvgIcon-root': {
          fill: 'white'
        }
      }
    },
    '& .MuiSvgIcon-root': {
      fill: 'var(--primary-color)'
    }
  }),
  menuItemContainer: {
    display: 'flex',
    width: '100%'
  },
  menuItemIconBox: {
    display: 'flex',
    minWidth: '31px',
    width: '31px',
    height: '31px',
    backgroundColor: 'var(--accent-normal-color)',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  menuItemIcon: {
    '& .MuiSvgIcon-root': {
      fill: 'var(--primary-color)'
    }
  },
  menuItemText: {
    lineHeight: '15px',
    width: '100%'
  },
  menuItemRadio: {
    '& .MuiRadio-root': {
      color: 'var(--primary-color)' // Default color for the radio button
    },
    '& .Mui-checked': {
      color: 'var(--primary-color)' // Color when the radio button is selected
    },
    '& .MuiCheckbox-root': {
      paddingRight: '0px',
      marginLeft: '10px'
    }
  },
  title: {
    fontSize: '14px'
  },
  description: {
    fontSize: '11px',
    color: 'var(--dark-gray)',
    fontWeight: '300'
  },
  loading: {
    animation: `$spin 1s linear infinite`,
    width: '15px',
    height: '15px',
    '& path': {
      fill: 'var(--primary-color)'
    }
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
