import { withTranslation } from 'react-i18next';
import Button from 'components/buttons/Button';
import useMediaQuery from 'utils/mediaQueries';
import React, { Fragment, useRef, useState } from 'react';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { isHighChartsType } from 'config/constants';
import { isTableChart } from 'components/chart/Chart';
import _ from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import { Check } from '@mui/icons-material';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { ChartIconFromType } from 'components/buttons/ChartIconFromType';

import { useMenuStyles } from './ChangeVisualizationButton.styles';

const ChangeVisualizationButton = ({
  changeToVisualizationType,
  t,
  hideButtonText,
  showModifyOption,
  hideTableCharts,
  visualizationTypes,
  currentVisualizationType,
  isModifyVisualizationBlockOpen,
  setShowModifyVisualization
}) => {
  const isMobile = useMediaQuery();
  const [isOpen, setIsOpen] = useState(false);
  const [actualVisualizationType, setActualVisualizationType] = useState(currentVisualizationType);
  const buttonRef = useRef(null);
  const [anchorPosition, setAnchorPosition] = useState(null);

  const menuStyles = useMenuStyles();

  const handleMenuOpen = () => {
    if (buttonRef.current) {
      const { top, left, height } = buttonRef.current.getBoundingClientRect();
      setAnchorPosition({ top: top + height, left: left });
    }
    setIsOpen(!isOpen);
  };

  const handleModifyVisualization = () => {
    debouncedChangeVisualizationType.cancel();
    changeToVisualizationType(actualVisualizationType);
    setShowModifyVisualization(!isModifyVisualizationBlockOpen);
    setIsOpen(false); // Close menu after modification
  };

  const debouncedChangeVisualizationType = _.debounce(type => {
    changeToVisualizationType(type);
  }, 300);

  let menuItems = [];
  if (showModifyOption && isHighChartsType(actualVisualizationType)) {
    menuItems = [
      <MenuItem
        sx={menuStyles}
        key="modify"
        divider
        onClick={handleModifyVisualization}
        onMouseEnter={() => {
          debouncedChangeVisualizationType.cancel();
          isOpen && debouncedChangeVisualizationType(actualVisualizationType);
        }}
      >
        <ListItemIcon>
          <EditIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText secondary={<b style={{ color: 'orange' }}>BETA</b>}>
          {t('modify-visualization.title')}
        </ListItemText>
      </MenuItem>
    ];
  }

  menuItems = menuItems.concat(
    visualizationTypes
      .filter(type => !hideTableCharts || !isTableChart(type))
      .map((type, index) => (
        <MenuItem
          sx={menuStyles}
          key={type + index}
          data-test="changeVisualizationTo"
          data-value={type}
          onClick={() => {
            debouncedChangeVisualizationType.cancel();
            changeToVisualizationType(type);
            setIsOpen(false);
            setActualVisualizationType(type);
          }}
          onMouseEnter={() => {
            debouncedChangeVisualizationType.cancel();
            isOpen && debouncedChangeVisualizationType(type);
          }}
        >
          <ListItemIcon>
            <ChartIconFromType type={type} />
          </ListItemIcon>
          <ListItemText>
            {type === actualVisualizationType ? (
              <b style={{ color: 'var(--secondary-color)' }}>
                {t(`chart-types.${type}`)} <Check />
              </b>
            ) : (
              t(`chart-types.${type}`)
            )}
          </ListItemText>
        </MenuItem>
      ))
  );

  return (
    <Fragment>
      <InfoTooltip text={t('answer-message.change-visualization')}>
        <Button
          ref={buttonRef}
          onClick={handleMenuOpen}
          className="button-secondary"
          showOnlyIcon={isMobile}
          text={!hideButtonText ? t('answer-message.change-visualization') : undefined}
          data-test="changeVisualization"
          aria-label={t('change-visualization-title')}
        >
          <span className="icon-chart" />
        </Button>
      </InfoTooltip>
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={isOpen}
        onClose={() => {
          debouncedChangeVisualizationType.cancel();
          changeToVisualizationType(actualVisualizationType);
          setIsOpen(false);
          setAnchorPosition(null); // Reset position
        }}
      >
        {menuItems}
      </Menu>
    </Fragment>
  );
};

export default withTranslation('veezoo')(ChangeVisualizationButton);
