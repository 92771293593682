import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import InfoTooltip from 'components/tooltips/InfoTooltip';

import IconClose from 'svg/v2/close.svg';

import { useStyles } from './Chip.styles';

const Chip = forwardRef(
  (
    {
      label,
      onClose: closeClick,
      onClick: containerClick,
      allowHover = false,
      showTooltip = false,
      greyed = false,
      smaller = false,
      ...attributes
    },
    ref
  ) => {
    const { classes, cx } = useStyles({ greyed, smaller });
    const onClose = event => {
      event.stopPropagation();
      closeClick();
    };

    const onClick = event => {
      event.stopPropagation();
      containerClick();
    };

    const chip = (
      <div
        ref={ref}
        className={cx(
          classes.container,
          (allowHover || containerClick) && classes.allowHover,
          containerClick && classes.cursorPointer
        )}
        onClick={containerClick ? onClick : null}
        {...attributes}
      >
        <div className={classes.textContainer}>{label}</div>
        {closeClick && <IconClose className={classes.closeIcon} onClick={onClose} data-test="CloseChip" />}
      </div>
    );

    return showTooltip ? <InfoTooltip text={label}>{chip}</InfoTooltip> : chip;
  }
);

export default Chip;

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  showTooltip: PropTypes.bool,
  allowHover: PropTypes.bool,
  greyed: PropTypes.bool,
  smaller: PropTypes.bool
};
