import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { TextField } from 'components/v3';

import { useStyles } from './PasswordField.styles';

const PasswordField = forwardRef(({ type, ...props }, ref) => {
  const [show, setShow] = useState(false);

  const classes = useStyles();

  const handleClick = () => setShow(prev => !prev);

  const EyeIcon = show ? VisibilityOffIcon : VisibilityIcon;
  const Icon = () => <EyeIcon fontSize="large" color="action" sx={classes} onClick={handleClick} />;

  return <TextField ref={ref} label="Password" type={show ? 'text' : 'password'} endIcon={<Icon />} {...props} />;
});

PasswordField.propTypes = {
  type: PropTypes.string
};

export default PasswordField;
