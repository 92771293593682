import { makeStyles } from 'tss-react/mui';

const defaultBackgroundColor = 'white';
const primaryColor = 'var(--primary-color)';

export const useStyles = makeStyles()((_, { direction, justifyContent }) => {
  return {
    container: {
      display: 'flex',
      flexDirection: direction === 'row' ? 'row' : 'column',
      justifyContent: justifyContent || 'space-between',
      '& > div': {
        '&:first-child': { marginRight: 5 },
        '&:last-child': { marginLeft: 5 }
      }
    },
    buttonContainer: {
      color: primaryColor,
      width: 'initial',
      border: 'solid thin var(--light-gray)',
      backgroundColor: defaultBackgroundColor,
      boxSizing: 'border-box',
      height: '36px',
      padding: '10px 16px',
      fontSize: '14px',
      minWidth: '110px',
      fontFamily: 'var(--font-family)',
      fontWeight: '400',
      borderRadius: '4px',
      textTransform: 'initial',
      textAlign: 'center',
      lineHeight: '1px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: 'var(--lightest-gray)'
      },
      '& > span': {
        verticalAlign: 'middle'
      }
    },
    buttonSelected: {
      backgroundColor: defaultBackgroundColor,
      cursor: 'default',
      border: `solid thin ${primaryColor}`,
      color: primaryColor,
      '&:hover': {
        backgroundColor: defaultBackgroundColor
      }
    },
    icon: {
      width: '15px',
      height: '15px',
      fill: primaryColor,
      verticalAlign: 'middle',
      marginRight: '7px'
    }
  };
});
