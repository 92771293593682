import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  container: {
    width: '30px',
    height: '30px',
    backgroundColor: 'var(--primary-color)',
    borderRadius: '50%',
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: '600',
    fontSize: '1rem'
  }
}));
