import { makeStyles } from 'tss-react/mui';

import { useTheme } from '@mui/material/styles';

const fontFamily = 'var(--font-family)';
const minHeight = 32;

const helperIcons = {
  top: 'initial',
  marginRight: '4px',
  justifyContent: 'flex-start',
  boxSizing: 'border-box',
  alignItems: 'end',
  padding: 0,
  '& > path': {
    fill: ({ disabled }) => (disabled ? 'var(--disabled-color)' : 'var(--primary-color)')
  }
};

export const useStyles = makeStyles()(() => {
  return {
    container: {
      position: 'relative',
      height: minHeight
    },

    menuItemIcon: {
      display: 'inline-flex',
      width: '20px',
      height: '20px',
      verticalAlign: 'middle',
      marginRight: '7px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--light-medium-gray)',
      borderRadius: '50%',
      '& > svg': {
        maxWidth: '20px',
        maxHeight: '10px',
        fill: 'var(--primary-color)'
      }
    },

    menuItemLabel: {
      display: 'inline-block',
      verticalAlign: 'middle'
    },

    menuItemFont: {
      fontSize: '12px',
      fontFamily: 'var(--font-family)'
    },

    userInitials: {
      backgroundColor: 'var(--primary-color)',
      color: 'white',
      fontSize: '8px'
    }
  };
});

export const useInputStyles = () => {
  return {
    '& .MuiOutlinedInput-notchedOutline': {
      top: 0,
      border: 'solid thin var(--light-gray)',
      borderRadius: '4px',
      '& > legend': {
        display: 'none'
      }
    }
  };
};

export const useAutocompleteStyles = () => {
  const theme = useTheme();
  return {
    '& .MuiAutocomplete-popupIndicator': {
      ...helperIcons,
      width: '13.5px',
      height: '10.5px'
    },
    '& .MuiAutocomplete-clearIndicator': {
      ...helperIcons,
      width: '15.5px',
      height: '15.5px',
      padding: '3px',
      backgroundColor: 'var(--light-gray)'
    },
    '& .MuiAutocomplete-endAdornment': {
      height: '14px',
      right: '9px'
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '3px 65px 3px 3px !important',
      minHeight,
      zIndex: 1,
      ...(theme.isDark ? {} : { backgroundColor: 'white' })
    },
    '& .MuiAutocomplete-input': {
      fontFamily,
      fontSize: '12px',
      color: 'inherit',
      lineHeight: '20px',
      boxSizing: 'border-box',
      padding: '9.5px 4px'
    }
  };
};
