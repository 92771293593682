import services from 'services';
import uuid1 from 'uuid/v1';

import { CHATMESSAGE_REQUEST, CHATMESSAGE_FAILURE, processAnswer } from 'store/modules/chat-messages';

export const SHARE_ANSWER_REQUEST = 'SHARE_ANSWER_REQUEST';
export const SHARE_ANSWER_SUCCESS = 'SHARE_ANSWER_SUCCESS';
export const SHARE_ANSWER_FAIL = 'SHARE_ANSWER_FAIL';

export const shareAnswer = (partialAnswerId, visualizationType, visualizationOptions) => async dispatch => {
  dispatch({ type: SHARE_ANSWER_REQUEST, partialAnswerId: partialAnswerId });
  const result = await services.shareAnswer(partialAnswerId, visualizationType, visualizationOptions);
  if (!result.success) {
    return dispatch({ type: SHARE_ANSWER_FAIL, partialAnswerId: partialAnswerId });
  } else {
    dispatch({
      type: SHARE_ANSWER_SUCCESS,
      sharedAnswerId: result.data.id,
      partialAnswerId: partialAnswerId,
      url: result.data.url
    });
  }
};

export const fetchSharedAnswer = sharedAnswerId => async dispatch => {
  const requestId = uuid1();
  dispatch({ type: CHATMESSAGE_REQUEST, requestId: requestId });
  const result = await services.getSharedAnswer(sharedAnswerId);
  if (!result.success) {
    dispatch({ type: CHATMESSAGE_FAILURE, requestId: requestId });
    throw Error(result.statusText);
  } else {
    processAnswer(result.data, requestId, -1, dispatch, {});
  }
};

export const uploadSharingPreviewImage = (sharedAnswerId, image) => async dispatch => {
  // We can fail silently, the link will just have no preview image
  await services.uploadSharingPreview(sharedAnswerId, image);
};

// describe a reducer
export const defaultSharingState = {
  sharedAnswers: []
};

export function sharing(state = defaultSharingState, action) {
  switch (action.type) {
    case SHARE_ANSWER_REQUEST: {
      let stateWithoutRequestedInterpretation = state.sharedAnswers.filter(
        s => s.partialAnswerId !== action.partialAnswerId
      );
      return {
        ...state,
        sharedAnswers: [
          ...stateWithoutRequestedInterpretation,
          {
            partialAnswerId: action.partialAnswerId,
            url: undefined,
            isLoading: true
          }
        ]
      };
    }

    case SHARE_ANSWER_FAIL:
      return {
        ...state,
        sharedAnswers: state.sharedAnswers.map(s => {
          if (s.partialAnswerId === action.partialAnswerId) {
            return {
              ...s,
              isLoading: false // we failed getting the sharing link
            };
          } else {
            return s;
          }
        })
      };

    case SHARE_ANSWER_SUCCESS:
      return {
        ...state,
        sharedAnswers: state.sharedAnswers.map(s => {
          if (s.partialAnswerId === action.partialAnswerId) {
            return {
              ...s,
              sharedAnswerId: action.sharedAnswerId,
              url: action.url,
              isLoading: false // we suceeded getting the sharing link
            };
          } else {
            return s;
          }
        })
      };

    default:
      return state;
  }
}
