import { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import MuiButton from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@material-ui/core/Radio';

import { fetchUserInformation } from 'store/modules/user';

import { trackEvent } from 'utils/eventTracking';
import useMediaQuery from 'utils/mediaQueries';

import LoaderIcon from 'svg/loader.svg';
import services from 'services';

import { useStyles } from './SystemZeroSelectButton.styles';

const SystemZeroSelectButton = ({ user, dispatch }) => {
  const isMobile = useMediaQuery();
  const classes = useStyles({ isMobile });
  const { t } = useTranslation();

  const [anchor, setAnchor] = useState(null);
  const [loading, setLoading] = useState(false);

  const items = [
    {
      title: 'Veezoo Classic',
      description: t('user-settings.fast-model-for-objective-questions'),
      icon: BoltRoundedIcon,
      isActivated: false
    },
    {
      title: 'Veezoo Neo (beta)',
      description: t('user-settings.smart-model-for-vague-questions'),
      icon: AutoAwesomeRoundedIcon,
      isActivated: true
    }
  ];

  const isActivated = user.isSystemZeroActivated;
  const selectedItem = items.find(item => item.isActivated === isActivated);

  const open = event => {
    setAnchor(event.currentTarget);
  };
  const close = () => setAnchor(null);

  const handleSystemZeroToggleChange = async value => {
    if (value === isActivated) return;

    close();
    setLoading(true);

    const update = { isSystemZeroActivated: value };
    const result = await services.updateUserSettings(update);
    if (result.success) {
      trackEvent('Veezoo Model Changed', update);
      dispatch(fetchUserInformation());
    }

    setLoading(false);
  };

  return (
    <div className={classes.container}>
      <MuiButton aria-controls="simple-menu" aria-haspopup="true" onClick={open} disabled={loading}>
        {loading ? <LoaderIcon className={classes.loading} /> : <selectedItem.icon />}
      </MuiButton>
      <Menu
        anchorEl={anchor}
        keepMounted
        open={!!anchor}
        onClose={close}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        {items.map((item, index) => (
          <MenuItem key={`menu_option_${index}`} onClick={() => handleSystemZeroToggleChange(item.isActivated)}>
            <div className={classes.menuItemContainer}>
              <div className={classes.menuItemIconBox}>
                <div className={classes.menuItemIcon}>
                  <item.icon />
                </div>
              </div>
              <div className={classes.menuItemText}>
                <span className={classes.title}>{item.title}</span>
                <br />
                <span className={classes.description}>{item.description}</span>
              </div>
              <div className={classes.menuItemRadio}>
                <Radio checked={isActivated === item.isActivated} disableRipple size="medium" />
              </div>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

const mapStateToProps = state => ({ user: state.user });

export default connect(mapStateToProps)(SystemZeroSelectButton);
