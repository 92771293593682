import { baseURL } from 'config/baseURL';
import { get, post } from './utils.js';

const fetchBoardQuestion = boardId => {
  const suffix = boardId ? '/' + boardId : '';
  return post(`${baseURL}question/board${suffix}?sessionId=${window.veezoo.sessionId}`, {});
};

const postQuestion = body => {
  const fullPayload = {
    ...body,
    sessionId: window.veezoo.sessionId
  };
  return post(`${baseURL}question`, fullPayload);
};

// Fetch (parse) an existing question by its id
const fetchQuestionById = questionId => {
  return post(`${baseURL}question/rephrased/${questionId}?sessionId=${window.veezoo.sessionId}`, {});
};

const fetchComplementaryQuestion = (answerId, interpretationId) =>
  get(`${baseURL}answers/partials/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

const fetchAnswerOfInterpretation = (answerId, interpretationId) =>
  get(`${baseURL}answers/alternatives/${answerId}/${interpretationId}?sessionId=${window.veezoo.sessionId}`);

export default {
  fetchBoardQuestion,
  postQuestion,
  fetchQuestionById,
  fetchComplementaryQuestion,
  fetchAnswerOfInterpretation
};
