import { useState, useMemo, memo } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import Input from 'components/v2/Input';
import Title from 'components/v2/Typography/Title';

import { Chip } from 'components/v3';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import handleKeyDown from 'utils/handleKeyDown';
import getTopTenItems from 'utils/getTopTenItems';

import { ChipListSkeleton } from './NodeContentSkeleton';

import EditIcon from '../EditIcon';
import CloseIcon from 'svg/v2/close.svg';

import { setIsEditingSynonyms } from 'store/modules/nodeEditor';

import { arraysContainSameStrings } from 'utils/arrayUtils';
import { trackEvent } from 'utils/eventTracking';

import styles from '../styles.scss';

const NodeSynonyms = ({
  name,
  uri,
  hasWritePermission,
  addSynonym,
  removeSynonym,
  updateSynonyms,
  synonyms,
  updatesLoading,
  updatesFailed,
  isEditingSynonyms,
  hasFetched,
  dispatch,
  t
}) => {
  const [synonymInputValue, setSynonymInputValue] = useState('');
  const [showAllSynonyms, setShowAllSynonyms] = useState(false);
  const [synonymInputBackup, setSynonymInputBackup] = useState(null);

  const loading = updatesLoading.some(item => item === 'synonyms');
  const failedLoading = updatesFailed.some(item => item === 'synonyms');

  const formattedSynonyms = useMemo(() => {
    if (!synonyms) return [];
    if (showAllSynonyms) return synonyms;
    return getTopTenItems(synonyms);
  }, [showAllSynonyms, synonyms]);

  const add = () => {
    if (synonymInputValue && synonymInputValue.trim() !== '') {
      addSynonym(synonymInputValue);
    }
    setSynonymInputValue('');
  };

  const closeSynonymInput = event => {
    event && event.stopPropagation();
    const synonymsHaveChanged = !arraysContainSameStrings(synonymInputBackup, synonyms);

    if (synonymsHaveChanged) {
      updateSynonyms(synonyms);
    }
    setSynonymInputValue('');
    setSynonymInputBackup(null);
    dispatch(setIsEditingSynonyms(false));
  };

  const toggleSynonymContainer = event => {
    if (!hasWritePermission || !hasFetched) return;
    if (!isEditingSynonyms) {
      trackEvent('Info Panel Synonym Input Clicked', { name, uri });
      dispatch(setIsEditingSynonyms(true));
      setSynonymInputBackup(synonyms);
    }
    return event.target === event.currentTarget && closeSynonymInput(event);
  };

  const handleSynonymInputValue = event => {
    const value = event.target.value;
    setSynonymInputValue(value);
  };

  const clearSynonymInputValue = () => setSynonymInputValue('');

  return (
    <div
      className={clsx(
        styles.section,
        isEditingSynonyms ? styles.isEditing : '',
        hasWritePermission ? styles.allowEditing : ''
      )}
    >
      <ClickAwayListener onClickAway={isEditingSynonyms ? closeSynonymInput : () => {}}>
        <div
          className={clsx(styles.editContainer)}
          onClick={toggleSynonymContainer}
          data-test="KnowledgeGraphConceptSynonymContainer"
        >
          <div
            className={clsx(styles.subheaderContainer, styles.flexSpaceBetween)}
            onClick={isEditingSynonyms ? closeSynonymInput : null}
          >
            <Title size="small">{t('sidebar.synonyms')}</Title>
            {hasWritePermission && hasFetched && <EditIcon loading={loading} warning={failedLoading} />}
          </div>
          {!hasFetched ? (
            <ChipListSkeleton />
          ) : (
            <>
              {isEditingSynonyms && (
                <Input
                  className={styles.mt15}
                  value={synonymInputValue}
                  onChange={handleSynonymInputValue}
                  placeholder="Enter new synonym"
                  endIcon={<CloseIcon className={styles.closeIcon} onClick={clearSynonymInputValue} />}
                  onKeyDown={event => handleKeyDown(event, add, closeSynonymInput)}
                  disabled={loading}
                  autoFocus
                  data-test="KnowledgeGraphConceptSynonymInput"
                />
              )}
              <div
                className={styles.chipContainer}
                onClick={
                  isEditingSynonyms ? event => event.target === event.currentTarget && closeSynonymInput(event) : null
                }
              >
                {synonyms && synonyms.length ? (
                  <>
                    {formattedSynonyms.map((synonym, index) => (
                      <div key={`${synonym}_${index}`} className={styles.chipCell}>
                        <Chip
                          label={synonym}
                          showTooltip
                          {...(isEditingSynonyms && { allowHover: true, onClose: () => removeSynonym(synonym) })}
                          data-test="KnowledgeGraphConceptSynonym"
                        />
                      </div>
                    ))}
                    {synonyms.length > formattedSynonyms.length && (
                      <div className={styles.chipCell}>
                        <Chip
                          label={`+${synonyms.length - 10}`}
                          onClick={() => setShowAllSynonyms(true)}
                          {...(isEditingSynonyms && { allowHover: true })}
                        />
                      </div>
                    )}
                  </>
                ) : (
                  !isEditingSynonyms && <span className={styles.noItemsFound}>{t('sidebar.no-synonyms-yet')}</span>
                )}
              </div>
            </>
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

const mapStateToProps = state => ({
  hasWritePermission: state.knowledgeGraphMeta.meta.hasWritePermission,
  updatesLoading: state.graph.updatesLoading,
  updatesFailed: state.graph.updatesFailed,
  isEditingSynonyms: state.nodeEditor.isEditingSynonyms
});

export default withTranslation('veezoo')(connect(mapStateToProps)(memo(NodeSynonyms)));
