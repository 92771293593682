import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TextField } from 'components/v3';

const NumberField = forwardRef(({ onChange: controlledOnChange, value: controlledValue, min, max, ...props }, ref) => {
  // We control the component internally, simulating an "uncontrolled" component because for uncontrolled components
  // it's not possible to limit the type of characters for an input, once we can't "control" it.
  const [uncontrolledValue, setUncontrolledValue] = useState('');

  const handleChange = event => {
    let innerValue = event.target.value;

    if (!isNaN(innerValue)) {
      if (min && innerValue < min) {
        innerValue = min;
      }
      if (max && innerValue > max) {
        innerValue = max;
      }

      if (controlledOnChange) {
        // We send back the whole "event" instead of the "innerValue" to keep convention from MUI that for
        // controlled components, the "onChange" usually returns the "event", like TextField, TextArea, Select, etc.
        event.target.value = innerValue;
        controlledOnChange(event);
      } else {
        setUncontrolledValue(innerValue);
      }
    }
  };

  return (
    <TextField fullWidth ref={ref} value={controlledValue || uncontrolledValue} onChange={handleChange} {...props} />
  );
});

export default NumberField;

NumberField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number,
  max: PropTypes.number
};
