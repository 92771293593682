import PropTypes from 'prop-types';
import nameSplit from 'utils/nameSplit';

import { useStyles } from './Avatar.styles.js';

const Avatar = ({ username = 'Admin' }) => {
  const { classes } = useStyles();
  const initials = nameSplit(username);
  return <div className={classes.container}>{initials}</div>;
};

export default Avatar;

Avatar.propTypes = {
  username: PropTypes.string
};
