import { connect } from 'react-redux';
import { Trans, withTranslation } from 'react-i18next';

import { fetchAnnotatedQuestion } from 'store/modules/annotatedQuestion';
import { toggleFollowUp } from 'store/modules/followUpState';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import Avatar from 'components/avatar/Avatar';

import styles from './user-message.scss';
import messageStyles from './message.scss';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import { chatIconButtonStyle } from './BaseMessage';
import Logo from '../logo/Logo';
import React from 'react';

export function UserMessage(props) {
  const { t, chatMessages, id, textAnswer, isRephraseMessage } = props;
  const classes = chatIconButtonStyle();
  const answerToQuestionMessage = chatMessages.find(msg => msg.localQuestionId && msg.localQuestionId === id);
  const questionId = answerToQuestionMessage
    ? answerToQuestionMessage.originalQuestionId || answerToQuestionMessage.questionId
    : 'no id at this point';

  let contextMessage;
  if (answerToQuestionMessage !== undefined && answerToQuestionMessage.referenceTo) {
    const { answerId, interpretationId } = answerToQuestionMessage.referenceTo;
    contextMessage = chatMessages.find(
      msg =>
        msg.id &&
        msg.answerId === answerId &&
        ((msg.answer && msg.answer.interpretationId === interpretationId) || // normal answer message
          (msg.interpretationIds && msg.interpretationIds.includes(interpretationId))) // board answer message
    );
  }

  let contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId;
  if (contextMessage !== undefined) {
    contextInterpretationId = answerToQuestionMessage.referenceTo.interpretationId;
    contextPartialAnswerId = answerToQuestionMessage.referenceTo.partialAnswerId;
    contextAnswerId = answerToQuestionMessage.referenceTo.answerId;
    contextMessageId = contextMessage.id;
  }

  const editButton = (
    <InfoTooltip text={t('tooltip.edit')} placement="left">
      <IconButton
        classes={classes}
        className={styles.editButton}
        onClick={() => {
          if (contextMessage) {
            // this is true only if it's followUp question
            props.scrollTo(contextMessage.id);
            // use a timeout here so we actually register a scrolling event and then handle
            // correct ordering of first scrolling and then setting the focus on the input box
            // see Chat.js
            setTimeout(() => props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer)), 200);
          } else {
            props.dispatch(fetchAnnotatedQuestion(questionId, textAnswer));
          }

          // TODO: all props are here undefined because of MultiAnswer
          if (contextInterpretationId && contextAnswerId && contextMessageId) {
            props.dispatch(
              toggleFollowUp(contextInterpretationId, contextPartialAnswerId, contextAnswerId, contextMessageId)
            );
          }
        }}
      >
        <EditIcon />
      </IconButton>
    </InfoTooltip>
  );

  const referenceToText = (
    <div className={styles.referenceToText}>
      <Trans i18nKey="reference-to-text">
        This Question is a FollowUp on
        <span className={styles.linkStyle} onClick={() => props.scrollTo(contextMessage.id)}>
          this Answer
        </span>
      </Trans>
    </div>
  );

  // For the rephrase message, we show the Veezoo logo instead of the user's avatar.
  const avatarIcon = isRephraseMessage ? (
    <div className={`${styles.userIcon} ${messageStyles.hideOnSmallScreens}`}>
      <Logo displayShortVersion alt="Veezoo logo" />
    </div>
  ) : (
    <div className={`${styles.userIcon} ${messageStyles.hideOnSmallScreens}`}>
      <Avatar size={50} />
    </div>
  );

  const userMessage = (
    <>
      <div className={styles.container}>
        <div className={styles.placeholder} />
        <div className={styles.messageUser}>
          {editButton}
          <div className={styles.messageBody} data-test="userMessageText">
            {textAnswer}
          </div>
          {contextMessage ? referenceToText : ''}
        </div>
        {avatarIcon}
      </div>
    </>
  );

  const rephraseMessage = (
    <>
      <div className={styles.containerParaphase}>
        <div className={styles.placeholder} />
        <div className={styles.messageRephrase}>
          {editButton}
          <div className={styles.messageRephraseBody}>
            <b>
              {t('rephrase.reformulated-to')}: <br />
            </b>
            <span data-test="userMessageText">{textAnswer}</span>
          </div>
        </div>
        {avatarIcon}
      </div>
    </>
  );

  /**
   * end helper components.
   */

  return (
    <div className={styles.wrapper} data-test="userMessage" data-question-id={answerToQuestionMessage?.questionId}>
      {isRephraseMessage ? rephraseMessage : userMessage}
    </div>
  );
}

const mapStateToProps = state => ({ chatMessages: state.chatMessages });

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(UserMessage));
