import React, { memo, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import Logo from 'components/logo/Logo';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ErrorBoundary from 'error-handler/ErrorBoundary';

import styles from './message.scss';
import Timestamp from 'components/time/Timestamp';

import { setFeedback, feedbackStates } from 'store/modules/recentFeedback';

import ThumbUp from 'svg/thumb-up.svg';
import ThumbDown from 'svg/thumb-down.svg';
import { withTranslation } from 'react-i18next';
import { showOrHideMessage, reAskWithLlmParser } from 'store/modules/chat-messages';
import IconButton from '@material-ui/core/IconButton';
import { VisibilityOff } from '@material-ui/icons';
import classnames from 'classnames';
import InfoTooltip from 'components/tooltips/InfoTooltip';
import { makeStyles } from '@material-ui/core';

import AutorenewIcon from '@mui/icons-material/Autorenew';

export const chatIconButtonStyle = makeStyles({
  root: props => ({
    border: 'solid 1px var(--border-color)',
    backgroundColor: 'white',
    padding: '8px',
    fontSize: '22px',
    // also set hover style (keep backgroundColor white, not transparent like per default)
    '&:hover': {
      backgroundColor: 'var(--lightest-gray)'
    },
    ...(props.width && { width: props.width }),
    ...(props.height && { height: props.height })
  })
});

export const BaseMessage = ({
  answerId,
  messageId,
  isLlmParserEnabled,
  isOnPremise,
  messages,
  interpretationId,
  localQuestionId,
  questionId,
  enableStandardFooter,
  timestamp,
  isFollowUp,
  content,
  customFooter,
  showVeezooIcon,
  baseMessageClasses,
  feedback,
  enableFeedback,
  followUpState,
  disableHideMessageButton,
  t,
  dispatch
}) => {
  const hideMessageAreaRef = React.useRef(null);
  const isFeedbackPositive = feedback === feedbackStates.POS;
  const isFeedbackNegative = feedback === feedbackStates.NEG;
  const classes = chatIconButtonStyle();

  // using a ref to keep track of whether the mouse is inside the hideMessageArea should not trigger a re-render
  const [isMouseInsideHideMessageArea, setIsMouseInsideHideMessageArea] = React.useState(false);
  const isSelectedFollowUp =
    followUpState && (messageId === followUpState.messageId && followUpState.interpretationId === interpretationId);

  const handleFeedbackClick = isPositive =>
    dispatch(setFeedback(answerId, messageId, questionId, localQuestionId, isPositive, isFollowUp, feedback));

  const baseMessageStyle = isSelectedFollowUp ? styles.followUpBaseMessage : styles.baseMessage;

  const handleMinimizeClick = useCallback(() => {
    if (localQuestionId) {
      dispatch(showOrHideMessage(true, localQuestionId));
    }
    dispatch(showOrHideMessage(true, messageId));
  }, [dispatch, localQuestionId, messageId]);

  const reAskUsingLlmParser = useCallback(() => {
    dispatch(reAskWithLlmParser(messageId, localQuestionId, messages, t));
  }, [dispatch, messageId, localQuestionId, messages]);

  const buttonClasses = classnames(styles.hideMessageButtonContainer, {
    [styles.hideMessageButtonContainerOnHover]: isMouseInsideHideMessageArea
  });

  // The hideMessageArea has visibility: hidden. Therefore, we cannot use the onMouseEnter and onMouseLeave events.
  // Simply using :hover in CSS doesn't work either, because we want to interact with the mouse with elements inside the hideMessageArea.
  // Instead, we use the mousemove event and check if the mouse is inside the hideMessageArea.
  // If the mouse enters / leaves the hideMessageArea, we show the button corresponding to the event.
  useEffect(() => {
    const handleMouseMove = e => {
      const hideMessageArea = hideMessageAreaRef.current;
      // we check if the mouse is inside the hideMessageArea by checking if the mouse coordinates are inside the hideMessageArea
      if (hideMessageArea) {
        const boundingRect = hideMessageArea.getBoundingClientRect();
        const isMouseInside =
          e.clientX >= boundingRect.left &&
          e.clientX <= boundingRect.right &&
          e.clientY >= boundingRect.top &&
          e.clientY <= boundingRect.bottom;

        setIsMouseInsideHideMessageArea(isMouseInside);
      }
    };
    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.messageContainer}>
        <div className={`${styles.icon} ${styles.hideOnSmallScreens}`}>
          {showVeezooIcon && <Logo displayShortVersion alt="Veezoo logo" />}
        </div>
        <div id="VeezooMessageHook" className={clsx(baseMessageStyle, baseMessageClasses)}>
          <ErrorBoundary>
            {messageId && !disableHideMessageButton && (
              <div className={styles.hideMessageArea} ref={hideMessageAreaRef}>
                <div className={buttonClasses}>
                  <InfoTooltip text={t('hide.tooltip')}>
                    <IconButton
                      classes={classes}
                      onClick={handleMinimizeClick}
                      size="medium"
                      aria-label={t('hide.tooltip')}
                    >
                      <VisibilityOff />
                    </IconButton>
                  </InfoTooltip>
                </div>
              </div>
            )}
            {content}
            {customFooter}
          </ErrorBoundary>
        </div>
        <div className={styles.placeholder} />
      </div>
      {enableStandardFooter && (
        <aside className={styles.footer}>
          <Timestamp time={timestamp} />
          {isFollowUp && (
            <>
              <span className={styles.separator}>•</span>
              FollowUp
            </>
          )}
          {!!enableFeedback && (
            <>
              <span className={styles.separator}>•</span>
              <InfoTooltip text={t('tooltip.give-positive-feedback')}>
                <div className={styles.iconContainer}>
                  <ThumbUp
                    className={clsx(styles.thumbIcon, isFeedbackPositive ? styles.thumbIconPositive : '')}
                    onClick={() => handleFeedbackClick(true)}
                  />
                </div>
              </InfoTooltip>
              <InfoTooltip text={t('tooltip.give-negative-feedback')}>
                <div className={styles.iconContainer}>
                  <ThumbDown
                    className={clsx(styles.thumbIcon, isFeedbackNegative ? styles.thumbIconNegative : '')}
                    onClick={() => handleFeedbackClick(false)}
                  />
                </div>
              </InfoTooltip>
              {isLlmParserEnabled && !isOnPremise && (
                <InfoTooltip text={t('tooltip.try-answering-again')}>
                  <AutorenewIcon className={styles.autorenewIcon} onClick={reAskUsingLlmParser} />
                </InfoTooltip>
              )}
            </>
          )}
        </aside>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  followUpState: state.followUpState,
  messages: state.chatMessages,
  isLlmParserEnabled: state.user.isLlmParserEnabled,
  isOnPremise: state.network.isOnPremise
});

export default withTranslation('veezoo', { withRef: true })(connect(mapStateToProps)(memo(BaseMessage)));

BaseMessage.propTypes = {
  answerId: PropTypes.string,
  localQuestionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  messageId: PropTypes.any,
  messages: PropTypes.array,
  interpretationId: PropTypes.any,
  questionId: PropTypes.any,
  feedback: PropTypes.any,
  enableStandardFooter: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  timestamp: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.number]),
  isFollowUp: PropTypes.bool,
  followUpState: PropTypes.object,
  customFooter: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  showVeezooIcon: PropTypes.bool,
  isComplementary: PropTypes.bool,
  disableHideMessageButton: PropTypes.bool,
  enableFeedback: PropTypes.any,
  baseMessageClasses: PropTypes.array,
  isLlmParserEnabled: PropTypes.bool,
  isOnPremise: PropTypes.bool
};

BaseMessage.defaultProps = {
  enableStandardFooter: true,
  showVeezooIcon: true,
  enableFeedback: false,
  feedback: feedbackStates.NONE
};
