import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form/dist/index.ie11';
import { yupResolver } from '@hookform/resolvers/dist/ie11/yup';
import * as yup from 'yup';
import { withTranslation } from 'react-i18next';

import PageLayout from 'layouts/PageLayout';

import { Button, TextField, PasswordField } from 'components/v3';

import Title from 'components/Signup/Title';

import { authenticate } from 'store/modules/network';

import styles from './index.module.scss';
import signupStyles from 'pages/Signup/signup.styles.scss';
import clsx from 'clsx';
import StepperContainer from 'components/Signup/StepperContainer/StepperContainer';

import { layouts } from 'config/constants';

import useMediaQuery from 'utils/mediaQueries';

const validationRules = yup.object().shape({
  email: yup
    .string()
    .required('E-mail is required')
    .email('Format should be like: email@domain.com'),
  password: yup.string().required('Password is required')
});

const defaultValues = {
  email: '',
  password: ''
};

const getErrorMessage = error => {
  return (error && error.message) || '';
};

const Login = ({ isLoginUnsuccessful, isAccountBlocked, dispatch }) => {
  const history = useHistory();
  const isMobile = useMediaQuery();

  const inputRef = useRef(null);

  const { handleSubmit, control, errors } = useForm({
    defaultValues,
    resolver: yupResolver(validationRules)
  });

  const onSubmit = data => {
    dispatch(authenticate(data.email, data.password));
  };

  useEffect(() => {
    // Used for Search Engine Indexing as it's a public page
    document.title = 'Veezoo - Login';
    return () => (document.title = 'Veezoo');
  });

  return (
    <PageLayout>
      <StepperContainer customStepper={<></>}>
        <div className={signupStyles.loginButton}>
          <Button
            onClick={() => history.push('/signup')}
            classes={{
              root: { fontSize: `${isMobile ? '16px' : '20px'} !important`, color: 'black', fontWeight: '600' }
            }}
            mode="simple"
            data-test="gotoSignupButton"
          >
            Sign up
          </Button>
        </div>
        <div className={signupStyles.centerBothDirectionsParent}>
          <div className={signupStyles.centerBothDirectionChild}>
            <div>
              <Title>Login</Title>
              <div className={signupStyles.subTitle}>Your answers are waiting for you. Just log in and ask!</div>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={signupStyles.mt_40}>
                  <div className={signupStyles.formField}>
                    <Controller
                      as={<TextField layout={layouts.signup} ref={inputRef} />}
                      id="EmailLoginInputHook"
                      name="email"
                      control={control}
                      label="Email"
                      error={Boolean(errors.email)}
                      helperText={getErrorMessage(errors.email)}
                    />
                  </div>
                  <div className={signupStyles.formField}>
                    <Controller
                      as={<PasswordField layout={layouts.signup} />}
                      id="PasswordLoginInputHook"
                      name="password"
                      control={control}
                      label="Password"
                      error={Boolean(errors.password)}
                      helperText={getErrorMessage(errors.password)}
                    />
                  </div>
                </div>
                <div className={clsx(signupStyles.mt_40, signupStyles.sendButton)}>
                  <Button type="submit" id="LoginInputButtonHook" layout={layouts.signup} size="large" mode="dark">
                    Log in to Veezoo
                  </Button>
                </div>
                {isLoginUnsuccessful && (
                  <div className={styles.wrongCredentials}>
                    Your login was not successful. <br />
                    Please check your credentials and/or IP and try again!
                  </div>
                )}
                {isAccountBlocked && (
                  <div className={styles.wrongCredentials}>
                    Your account is temporarily blocked due to <br />
                    too many wrong attempts to login. <br />
                    <br />
                    Please try again in 15 minutes or <a href="mailto:support@veezoo.com">contact us</a>.
                  </div>
                )}
                <div className={styles.forgotPassword}>
                  <a onClick={() => history.push('/password/reset')}>Forgot your password? We got your back.</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </StepperContainer>
    </PageLayout>
  );
};

export default withTranslation('veezoo')(Login);
